<template>
  <div
    v-if="$can('create', modulo)"
    class="d-flex align-items-center justify-content-end"
  >
    <periodo-create
      v-if="!observacion_periodo"
      :nombre_modal="`modal-create-periodo-${id_persona_rol_alumno}`"
      :id_persona_rol_alumno.sync="id_persona_rol_alumno"
      :id_periodo.sync="id_periodo"
      :id_cursos.sync="id_cursos"
      :nombre_estudiante="nombre_estudiante"
      :curso.sync="curso"
    />
    <periodo-update
      v-else
      :nombre_modal="`modal-update-periodo-${id_persona_rol_alumno}`"
      :observacion="observacion_periodo"
      :id_persona_rol_alumno.sync="id_persona_rol_alumno"
      :id_periodo.sync="id_periodo"
      :id_cursos.sync="id_cursos"
      :nombre_estudiante="nombre_estudiante"
      :curso.sync="curso"
    />

    <BButton
      v-if="!observacion_periodo"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="success"
      class="btn"
      @click="btnAbrir(`modal-create-periodo-${id_persona_rol_alumno}`)"
    >
      <span class="text-nowrap">Crear observación</span>
    </BButton>
    <BButton
      v-else
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="primary"
      class="btn"
      @click="btnAbrir(`modal-update-periodo-${id_persona_rol_alumno}`)"
    >
      <span class="text-nowrap">Editar observación</span>
    </BButton>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import PeriodoCreate from './PeriodoModal/PeriodoCreate.vue'
import PeriodoUpdate from './PeriodoModal/PeriodoUpdate.vue'

export default {
  components: {
    BButton,
    PeriodoCreate,
    PeriodoUpdate,
  },
  directives: {
    Ripple,
  },
  props: {
    modulo: {
      type: String,
      required: false,
    },

    observacion_periodo:{
      type: Object,
      required: false,
    },
    id_persona_rol_alumno: {
      type: Number,
      required: true,
    },
    id_periodo: {
      type: Number,
      required: true,
    },
    id_cursos: {
      type: Number,
      required: true,
    },
    nombre_estudiante: {
      type: String,
      required: true,
    },
    curso: {
      type: String,
      required: true,
    },
  },
  methods: {
    btnAbrir(nombre_modal) {
      this.$bvModal.show(nombre_modal)
    },
  },
}
</script>
