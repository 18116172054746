<template>
  <BModal
    :id="nombre_modal"
    :title="title"
    centered
    size="lg"
    :hide-footer="true"
    :ok-title="okbtn"
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    no-close-on-esc
    :ok-disabled="this.v$.informe_anual.$errors.length > 0"
    @ok.prevent="submitOption"
  >
    <BOverlay
      :show="cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <b-form
        ref="form"
          class="repeater-form"
      >

        <!-- PANEL DE INFORMACIÓN -->
        <BRow
          class=" mt-1 pb-0 pt-0 mb-2"
          style="padding-bottom: -10px !important;"
        >
            <!-- field: CURSO -->
            <BCol
              cols="12"
              md="6"
              class="text-left pl-2 pb-0 pt-0 mb-1 mt-0"
            >
              <BCardText><b>Curso:</b> {{curso}}</BCardText>
            </BCol>
            <!-- field: ESTUDIANTE -->
            <BCol
              cols="12"
              md="12"
              class="text-left pl-2 pb-0 pt-0 mb-1 mt-0"
            >
              <BCardText><b>Estudiante:</b> {{nombre_estudiante}}</BCardText>
            </BCol>
        </BRow>

        <!-- TÍTULO TABLA -->
        <BRow v-if="$can('update', 'asistenciasInformes')"
          class="border-top mt-0 pb-0 pt-2 mb-0"
          style="padding-bottom: -10px !important;"
        >
          <BCol
            cols="6"
            lg="3"
            md="6"
            sm="6"
            class="text-left pl-2 pb-0 pt-0 mb-0 mt-0"
          >
            <BFormGroup
              label="Días trabajados"
              label-for="dias_trabajados"
            >
              <BFormInput
                id="dias_trabajados"
                v-model="informe_anual.dias_trabajados"
                type="number"
                @input="calculaAsistencias"
              />
            </BFormGroup>
          </BCol>
          <BCol
            cols="6"
            lg="3"
            md="6"
            sm="6"
            class="text-left pl-2 pb-0 pt-0 mb-0 mt-0"
          >
            <BFormGroup
              label="Días de asistencia"
              label-for="dias_asistencia"
            >
              <BFormInput
                id="dias_asistencia"
                v-model="informe_anual.dias_asistencia"
                type="number"
                :state="v$.informe_anual.dias_asistencia.$error === true
                  ? false
                  : null"
                @blur.native="v$.informe_anual.dias_asistencia.$touch"
                @keyup="v$.informe_anual.dias_asistencia.$touch"
                @input="calculaAsistencias"
              />
              <BFormInvalidFeedback
                v-if="v$.informe_anual.dias_asistencia.$error"
                id="observacionInfo"
                class="text-center"
              >
                <p v-for="error of v$.informe_anual.dias_asistencia.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>
          <BCol
            cols="6"
            lg="3"
            md="6"
            sm="6"
            class="text-left pl-2 pb-0 pt-0 mb-0 mt-0"
          >
            <BFormGroup
              label="Días de inasistencia"
              label-for="dias_inasistencia"
            >
              <BFormInput
                id="dias_inasistencia"
                v-model="informe_anual.dias_inasistencia"
                type="number"
                disabled
              />
            </BFormGroup>
          </BCol>
          <BCol
            cols="6"
            lg="3"
            md="6"
            sm="6"
            class="text-left pl-2 pb-0 pt-0 mb-0 mt-0"
          >
            <BFormGroup
              label="% de asistencia"
              label-for="porcentaje_asistencia"
            >
              <BInputGroup class="input-group-merge">
                <BFormInput
                  id="porcentaje_asistencia"
                  v-model="informe_anual.porcentaje_asistencia"
                  type="number"
                  disabled
                />
                <BInputGroupAppend is-text>
                  %
                </BInputGroupAppend>
              </BInputGroup>
            </BFormGroup>
          </BCol>
        </BRow>

        <!-- OBSERACIONES -->
        <BRow
          class="border-bottom mt-0 pb-0 pt-2 mb-0"
          style="padding-bottom: -10px !important;"
        >
          <BCol
            cols="12"
            md="12"
            class="text-left pl-2 pb-0 pt-0 mb-0 mt-1"
          >
            <BFormGroup
              label="Observación *"
              label-for="observacion"
            >
              <b-form-textarea
                id="observacion"
                v-model="informe_anual.observacion"
                placeholder="Ingrese una observación"
                :state="v$.informe_anual.observacion.$error === true
                  ? false
                  : null"
                @blur.native="v$.informe_anual.observacion.$touch"
              />
              <BFormInvalidFeedback
                v-if="v$.informe_anual.observacion.$error"
                id="observacionInfo"
                class="text-right"
              >
                <p v-for="error of v$.informe_anual.observacion.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>
        </BRow>


        <!-- ACCIONES FOOTER -->
        <div class="text-right pt-3">
          <!-- Action Buttons -->
          <BButton
            variant="outline-secondary"
            class="mr-1"
            @click="closeModal"
          >
            Cancelar
          </BButton>

          <BButton
            v-if="$can('create' , 'informePerso') || $can('update', 'informePerso')"
            variant="primary"
            class="mr-25"
            @click="submitOption"
          >
            {{ okbtn }}
          </BButton>
        </div>
      </b-form>
    </BOverlay>
  </BModal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BOverlay, BFormDatepicker, BFormTimepicker,
  BFormTextarea, BModal, VBModal, BInputGroup, BInputGroupAppend,
  BCardTitle, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import { mapGetters, mapActions } from 'vuex'

// CLEAVE
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

// FORMATOS
import { formatos } from '@/mixins/formatos'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, maxValue, helpers } from '@vuelidate/validators'

// COMPONENTES RECICLADOS
import colLinea from '../../../components/Form/colLinea.vue'
import btnSubmit from '../../../components/Form/btnSubmit.vue'

export default {
  components: {
    // ETIQUETAS
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BAvatar,
    BOverlay,
    BFormDatepicker,
    BFormTimepicker,
    BFormTextarea,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    BCardTitle,
    BCardText,
    VBModal,
    vSelect,
    Cleave,

    // COMPONENTES RECICLADO
    colLinea,
    btnSubmit,
  },
  directives: {
    'BModal': VBModal,
    Ripple,
  },
  mixins: [formatos],
  data() {
    return {
      cargando: false,
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
    }),
  },
  props: {
    informe_anual: {
      type: Object,
      required: true,
    },
    nombre_modal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    id_persona_rol_alumno: {
      type: Number,
      required: true,
    },
    nombre_estudiante: {
      type: String,
      required: true,
    },
    okbtn: {
      type: String,
      default: 'Guardar',
    },
    curso: {
      type: String,
      required: true,
    },
  },
  validations() {
    return {
      informe_anual: {
        observacion: {
          // $autoDirty: true,
          // required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 200 caracteres.', maxLength(200)),
        },
        dias_asistencia: {
          maxValue: helpers.withMessage('No puede ser mayor a los días trabajados.', maxValue(this.informe_anual.dias_trabajados)),
        },
      }
    }
  },
  mounted() {
    this.setAsistencias(this.informe_anual)
  },
  methods: {
    ...mapActions({
    }),
    setAsistencias(informe_anual) {
      const {
        dias_trabajados,
        dias_asistencia,
        dias_inasistencia,
        porcentaje_asistencia,
      } = informe_anual
      if (dias_trabajados === null) {
        this.informe_anual.dias_trabajados = 0
      }
      if (dias_asistencia === null) {
        this.informe_anual.dias_asistencia = 0
      }
      if (dias_inasistencia === null) {
        this.informe_anual.dias_inasistencia = 0
      }
      if (porcentaje_asistencia === null) {
        this.informe_anual.porcentaje_asistencia = 0
      }
    },
    calculaAsistencias() {
      this.informe_anual.dias_trabajados = this.formatSoloNumeros(this.informe_anual.dias_trabajados)
      this.informe_anual.dias_asistencia = this.formatSoloNumeros(this.informe_anual.dias_asistencia)
      const dias_trabajados = parseInt(this.informe_anual.dias_trabajados)
      let dias_asistencia = parseInt(this.informe_anual.dias_asistencia)
      this.informe_anual.dias_inasistencia = this.informe_anual.dias_trabajados -this.informe_anual.dias_asistencia
      this.informe_anual.porcentaje_asistencia = Math.trunc((dias_asistencia / dias_trabajados) * 100)
    },
    submitOption() {
      this.v$.informe_anual.$touch()
      if (!this.v$.informe_anual.$invalid) {
        this.informe_anual.id_persona_rol_alumno = this.id_persona_rol_alumno
        this.informe_anual.tipo = 6 // TIPO 6: INFORME ANUAL
        this.$emit('processForm', this.informe_anual)
        this.v$.informe_anual.$reset()
      }
    },
    closeModal() {
      this.$bvModal.hide(this.nombre_modal)
      this.$emit('resetData')
      this.v$.informe_anual.$reset()
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
