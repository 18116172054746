<template>
  <div
    v-if="$can('create', modulo)"
    class="d-flex align-items-center justify-content-end"
  >
    <anual-create
      v-if="!observacion_anual"
      :nombre_modal="`modal-create-anual-${id_persona_rol_alumno}`"
      :id_persona_rol_alumno.sync="id_persona_rol_alumno"
      :id_cursos.sync="id_cursos"
      :nombre_estudiante="nombre_estudiante"
      :curso.sync="curso"
    />
    <anual-update
      v-else
      :nombre_modal="`modal-update-anual-${id_persona_rol_alumno}`"
      :observacion="observacion_anual"
      :id_persona_rol_alumno.sync="id_persona_rol_alumno"
      :id_cursos.sync="id_cursos"
      :nombre_estudiante="nombre_estudiante"
      :curso.sync="curso"
    />

    <BButton
      v-if="!observacion_anual"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="success"
      class="btn"
      @click="btnAbrir(`modal-create-anual-${id_persona_rol_alumno}`)"
    >
      <span class="text-nowrap">Crear observación</span>
    </BButton>
    <BButton
      v-else
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="primary"
      class="btn"
      @click="btnAbrir(`modal-update-anual-${id_persona_rol_alumno}`)"
    >
      <span class="text-nowrap">Editar observación</span>
    </BButton>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AnualCreate from './AnualModal/AnualCreate.vue'
import AnualUpdate from './AnualModal/AnualUpdate.vue'

export default {
  components: {
    BButton,
    AnualCreate,
    AnualUpdate,
  },
  directives: {
    Ripple,
  },
  props: {
    modulo: {
      type: String,
      required: false,
    },

    observacion_anual:{
      type: Object,
      required: false,
    },
    id_persona_rol_alumno: {
      type: Number,
      required: true,
    },
    id_cursos: {
      type: Number,
      required: true,
    },
    nombre_estudiante: {
      type: String,
      required: true,
    },
    curso: {
      type: String,
      required: true,
    },
    fecha_retiro: {
      type: String,
      required: false,
    },

  },
  methods: {
    btnAbrir(nombre_modal) {
      this.$bvModal.show(nombre_modal)
    },
  },
}
</script>
